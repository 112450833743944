var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-content-center",
    attrs: {
      "id": "donut-chart"
    }
  }, [_c('VueApexCharts', {
    ref: "donutChart",
    attrs: {
      "width": "550",
      "type": "donut",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }