var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('VueApexCharts', {
    ref: "chart",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }