var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-row', {
    attrs: {
      "gutter": 12
    }
  }, _vm._l(_vm.legendData, function (data, index) {
    return _c('el-col', {
      key: index,
      staticStyle: {
        "display": "inline-flex",
        "align-items": "baseline"
      },
      attrs: {
        "span": 12,
        "xs": 24,
        "sm": 12,
        "lg": 12,
        "xl": 12
      }
    }, [_c('div', {
      staticClass: "square",
      style: {
        'background-color': data.color
      }
    }), _c('p', [_c('span', {
      staticStyle: {
        "font-weight": "bolder",
        "color": "black"
      }
    }, [_vm._v(" " + _vm._s(((data.percent.toFixed(2)) + "%")) + " ")]), _c('br'), _c('u', {
      staticStyle: {
        "color": "darkgray"
      }
    }, [_vm._v(" " + _vm._s(data.name) + " ")])])]);
  }), 1), _c('el-row', [_c('el-col', [_c('pie-chart', {
    ref: "bestPerformingChart",
    attrs: {
      "chartData": _vm.data
    },
    on: {
      "updateLegend": _vm.updateLegendData
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }