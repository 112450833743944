var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "bar-chart"
    }
  }, [_c('VueApexCharts', {
    ref: "apexChartBar",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }