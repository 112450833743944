var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dashboard"
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('el-row', [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('el-tabs', {
    on: {
      "tab-click": _vm.handleDashboardTabClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c('el-tab-pane', {
    staticClass: "content-vertical-centre grid-content panel panel-primary ",
    attrs: {
      "label": "Calendar Year/Financial Year",
      "name": "ytdfytd"
    }
  }, [_c('el-col', {
    attrs: {
      "lg": 6,
      "md": 6,
      "sm": 24
    }
  }, [_c('el-select', {
    staticStyle: {
      "align-self": "flex-end",
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select"
    },
    model: {
      value: _vm.selectedTotalSavings,
      callback: function ($$v) {
        _vm.selectedTotalSavings = $$v;
      },
      expression: "selectedTotalSavings"
    }
  }, _vm._l(_vm.timeframeOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-col', {
    staticClass: "pl-4",
    attrs: {
      "lg": 18,
      "md": 18,
      "sm": 24
    }
  }, [_c('div', {
    staticClass: "py-2 grid-content panel"
  }, [_c('div', {
    staticClass: "inline-flex",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v("Total savings")]), _c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalSavings) + " ")])]), _c('el-button', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.savingReport.apply(null, arguments);
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "printer",
      "size": 20
    }
  }), _vm._v(" Print Report ")], 1)], 1)])], 1), _c('el-tab-pane', {
    staticClass: "content-vertical-centre grid-content panel panel-primary ",
    attrs: {
      "label": "Date Range",
      "name": "custom"
    }
  }, [_c('el-col', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "lg": 6,
      "md": 4,
      "sm": 24
    }
  }, [_c('el-date-picker', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "70%"
    },
    attrs: {
      "type": "daterange",
      "unlink-panels": "",
      "range-separator": "-",
      "format": "dd/MM/yyyy",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "shortcuts": _vm.shortcuts,
      "size": 'default'
    },
    model: {
      value: _vm.valueRange,
      callback: function ($$v) {
        _vm.valueRange = $$v;
      },
      expression: "valueRange"
    }
  }), _c('el-button', {
    staticStyle: {
      "width": "20%",
      "align-items": "center"
    },
    attrs: {
      "type": 'outline-primary',
      "size": 'small'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.fetchCustomDateSaving.apply(null, arguments);
      }
    }
  }, [_vm._v(" Go ")])], 1), _c('el-col', {
    staticClass: "pl-4",
    attrs: {
      "lg": 18,
      "md": 18,
      "sm": 24
    }
  }, [_c('div', {
    staticClass: "py-2 grid-content panel"
  }, [_c('div', {
    staticClass: "inline-flex",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v("Total savings")]), _c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalSavings) + " ")])]), _c('el-button', [_c('mdicon', {
    attrs: {
      "name": "printer",
      "size": 20
    }
  }), _vm._v(" Print Report ")], 1)], 1)])], 1)], 1)], 1)], 1)], 1)], 1), _c('el-row', {
    staticClass: "flex align-stretch",
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-row', [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Savings Snapshot")])]), _c('column-chart', {
    ref: "saving_chart",
    attrs: {
      "chartData": _vm.savingSnapshotChartData
    }
  })], 1), _c('el-card', {
    staticClass: "grid-content mt-3",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Search RFTs by Date Range")])]), _c('el-form', {
    ref: "productReportForm",
    attrs: {
      "model": _vm.rftForm,
      "rules": _vm.rules,
      "inline": true
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "span": 6
    }
  }, [_c('el-date-picker', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "daterange",
      "unlink-panels": "",
      "range-separator": "-",
      "format": "dd/MM/yyyy",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "shortcuts": _vm.shortcuts,
      "size": 'default'
    },
    model: {
      value: _vm.rftFormDate,
      callback: function ($$v) {
        _vm.rftFormDate = $$v;
      },
      expression: "rftFormDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "span": 8
    }
  }, [_c('el-form-item', [_c('el-button', {
    staticStyle: {
      "display": "block",
      "width": "100%"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v("Go")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 8,
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Best Performing")])]), _c('best-performing-chart', {
    ref: "best_performing",
    attrs: {
      "data": _vm.bestPerformingChartData,
      "total": _vm.savings
    }
  })], 1)], 1)], 1), _c('el-row', {
    staticClass: "flex align-stretch",
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12,
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Top 10 Saving Items")])]), _c('donut-chart', {
    ref: "toptenSaving",
    attrs: {
      "chartData": _vm.topSavingItems
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12,
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Top 5 Supplier Savers")])]), _c('bar-chart', {
    ref: "topfiveSaving",
    attrs: {
      "chartData": _vm.topSupplierSaver
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('el-row', [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('el-tabs', {
    on: {
      "tab-click": _vm.handleDashboardTabCostClick
    },
    model: {
      value: _vm.activeNameCost,
      callback: function ($$v) {
        _vm.activeNameCost = $$v;
      },
      expression: "activeNameCost"
    }
  }, [_c('el-tab-pane', {
    staticClass: "content-vertical-centre grid-content panel panel-secondary ",
    attrs: {
      "label": "Calendar Year/Financial Year",
      "name": "ytdfytd"
    }
  }, [_c('el-col', {
    attrs: {
      "lg": 6,
      "md": 6,
      "sm": 24
    }
  }, [_c('el-select', {
    staticStyle: {
      "align-self": "flex-end",
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select"
    },
    model: {
      value: _vm.selectedTotalCosts,
      callback: function ($$v) {
        _vm.selectedTotalCosts = $$v;
      },
      expression: "selectedTotalCosts"
    }
  }, _vm._l(_vm.timeframeOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-col', {
    staticClass: "pl-4",
    attrs: {
      "lg": 18,
      "md": 18,
      "sm": 24
    }
  }, [_c('div', {
    staticClass: "py-2 grid-content panel"
  }, [_c('div', {
    staticClass: "inline-flex",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v("Total costs")]), _c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalCosts) + " ")])]), _c('el-button', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.savingReportLowerSection.apply(null, arguments);
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "printer",
      "size": 20
    }
  }), _vm._v(" Print Report ")], 1)], 1)])], 1), _c('el-tab-pane', {
    staticClass: "content-vertical-centre grid-content panel panel-secondary",
    attrs: {
      "label": "Date Range",
      "name": "custom"
    }
  }, [_c('el-col', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "lg": 6,
      "md": 4,
      "sm": 24
    }
  }, [_c('el-date-picker', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "70%"
    },
    attrs: {
      "type": "daterange",
      "unlink-panels": "",
      "range-separator": "-",
      "format": "dd/MM/yyyy",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "shortcuts": _vm.shortcuts,
      "size": 'default'
    },
    model: {
      value: _vm.valueRangeCost,
      callback: function ($$v) {
        _vm.valueRangeCost = $$v;
      },
      expression: "valueRangeCost"
    }
  }), _c('el-button', {
    staticStyle: {
      "width": "20%",
      "align-items": "center"
    },
    attrs: {
      "type": 'outline-primary',
      "size": 'small'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.fetchCustomDateCosts.apply(null, arguments);
      }
    }
  }, [_vm._v(" Go ")])], 1), _c('el-col', {
    staticClass: "pl-4",
    attrs: {
      "lg": 18,
      "md": 18,
      "sm": 24
    }
  }, [_c('div', {
    staticClass: "py-2 grid-content panel"
  }, [_c('div', {
    staticClass: "inline-flex",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v("Total costs")]), _c('h4', {
    staticStyle: {
      "align-self": "flex-end"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalCosts) + " ")])]), _c('el-button', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.savingReportLowerSection.apply(null, arguments);
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "printer",
      "size": 20
    }
  }), _vm._v(" Print Report ")], 1)], 1)])], 1)], 1)], 1)], 1)], 1)], 1), _c('el-row', {
    staticClass: "flex align-stretch",
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12,
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Top 10 Cost Items")])]), _c('donut-chart', {
    ref: "toptenCostitems",
    attrs: {
      "chartData": _vm.topCostItems
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12,
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Top 5 Spend per Supplier")])]), _c('bar-chart', {
    ref: "topfiveSpend",
    attrs: {
      "chartData": _vm.topSupplierSpend
    }
  })], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-card', [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('el-col', {
    attrs: {
      "lg": 12,
      "md": 12,
      "sm": 24
    }
  }, [_c('h6', [_vm._v("Live Tenders")])]), _c('el-col', {
    attrs: {
      "lg": 12,
      "md": 12,
      "sm": 24
    }
  }, [_c('el-button', {
    staticClass: "float-right",
    attrs: {
      "size": 'mini',
      "type": 'primary',
      "plain": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.liveTendersDownload();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "microsoft-excel",
      "size": 20
    }
  }), _vm._v(" Download ")], 1)], 1)], 1), _c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.liveTendersTableData,
      "default-sort": {
        prop: 'date',
        order: 'descending'
      }
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "Tender Name",
      "sortable": ""
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "closing_date",
      "label": "Closing Date",
      "sortable": ""
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "total_bids",
      "label": "Total Bids",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.NumberUtil.formatNumber(scope.row.total_bids)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "no_bids",
      "label": "Items w/o Bids",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.NumberUtil.formatNumber(scope.row.no_bids)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "current_savings",
      "label": "Current Savings",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.NumberUtil.formatCurrency(scope.row.current_savings)) + " ")];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }