var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dashboard"
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('el-card', {
    staticClass: "bg-success",
    attrs: {
      "shadow": "never"
    }
  }, [_c('el-row', [_c('el-col', {
    staticClass: "d-flex",
    attrs: {
      "lg": 6,
      "md": 4,
      "sm": 24
    }
  }, [_c('el-date-picker', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "70%"
    },
    attrs: {
      "type": "daterange",
      "unlink-panels": "",
      "range-separator": "-",
      "format": "dd/MM/yyyy",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "size": 'default'
    }
  }), _c('el-button', {
    staticStyle: {
      "width": "20%",
      "align-items": "center"
    },
    attrs: {
      "type": 'outline-primary',
      "size": 'small'
    }
  }, [_vm._v(" Go ")])], 1), _c('el-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "lg": 18,
      "md": 18,
      "sm": 24
    }
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.savingReport.apply(null, arguments);
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "printer",
      "size": 20
    }
  }), _vm._v(" Print Report ")], 1)], 1)], 1)], 1)], 1)], 1), _c('el-row', {
    staticClass: "flex align-stretch",
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Discount Win Rate Bids vs PBS")])]), _c('div', {
    attrs: {
      "id": "-chart"
    }
  }, [_c('discount-win-rate-bid-chart', {
    ref: "discountWinRateBidsVsPbs",
    attrs: {
      "series": _vm.discountWinRateBidPbs.series,
      "categories": _vm.discountWinRateBidPbs.categories
    }
  })], 1)])], 1), _c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Gross Margin Win Rate Bids vs COGS")])]), _c('div', {
    attrs: {
      "id": "-chart"
    }
  }, [_c('discount-win-rate-bid-chart', {
    ref: "grossMarginWinRateVsCogs",
    attrs: {
      "series": _vm.discountWinRateBidCogs.series,
      "categories": _vm.discountWinRateBidCogs.categories
    }
  })], 1)])], 1)], 1), _c('el-row', {
    staticClass: "flex align-stretch",
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Total Value Win/Lost Bids")])]), _c('div', {
    attrs: {
      "id": "win-lost-chart"
    }
  }, [_c('win-lost-bid-chart', {
    ref: "totalValueWinLostBid",
    attrs: {
      "series": _vm.winLostBid.series,
      "categories": _vm.winLostBid.categories
    }
  })], 1)])], 1), _c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Average Cost Margin on COGS")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Last 5 Tenders")])]), _c('div', {
    attrs: {
      "id": "-chart"
    }
  }, [_c('average-cost-margin-chart', {
    ref: "averageCostMarginOnCogs",
    attrs: {
      "series": _vm.averageCostMargin.series,
      "categories": _vm.averageCostMargin.categories
    }
  })], 1)])], 1)], 1), _c('el-row', {
    staticClass: "flex align-stretch",
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Average Bid Gap PBS - Weighted")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Last 5 Tenders Average")])]), _c('div', {
    attrs: {
      "id": "-chart"
    }
  }, [_c('average-bid-gaps-pbs-chart', {
    ref: "averageBidGapPbsWeighted",
    attrs: {
      "series": _vm.averageBidGapsPbsWeight.series,
      "categories": _vm.averageBidGapsPbsWeight.categories
    }
  })], 1)])], 1), _c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 16
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Average Bid Gap PBS - Simple Average")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Last 5 Tenders Average")])]), _c('div', {
    attrs: {
      "id": "-chart"
    }
  }, [_c('average-bid-gaps-pbs-chart', {
    ref: "averageBidGapPbsSimpleAverage",
    attrs: {
      "series": _vm.averageBidGapsPbsSimple.series,
      "categories": _vm.averageBidGapsPbsSimple.categories
    }
  })], 1)])], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16,
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('el-form', {
    ref: "productReportForm",
    attrs: {
      "model": _vm.rftForm,
      "rules": _vm.rules,
      "inline": true
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('h6', [_vm._v("Individual Search Item")])]), _c('el-row', [_c('el-col', {
    staticClass: "d-flex",
    attrs: {
      "lg": 6,
      "md": 4,
      "sm": 24
    }
  }, [_c('el-date-picker', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "70%"
    },
    attrs: {
      "type": "daterange",
      "unlink-panels": "",
      "range-separator": "-",
      "format": "dd/MM/yyyy",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "shortcuts": _vm.shortcuts,
      "size": 'default'
    },
    model: {
      value: _vm.rftFormDate,
      callback: function ($$v) {
        _vm.rftFormDate = $$v;
      },
      expression: "rftFormDate"
    }
  }), _c('el-button', {
    staticStyle: {
      "width": "20%",
      "align-items": "center"
    },
    attrs: {
      "type": "primary",
      "size": 'small'
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v(" Go ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }